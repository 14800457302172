<template>
    <a-modal :visible="modal.visible && modal.type == 'locker'" :footer="null" :centered="true" @cancel="onClose" :width="'75%'">
        <bh-loading :show="loading" />
        <h5>Locker</h5>
        <a-form-model style="margin-top:24px" :model="lockerModal" ref="lockerModal">
            <a-row :gutter="16">
                <a-col :span="4">
                    <a-form-model-item label="Locker ID" prop="lID">
                        <a-input style="width:100%" size="large" v-model="lockerModal.lID" placeholder="Example value: 1" >
                        </a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="5">
                    <a-form-model-item label="Locker #" prop="name" :rules="req('Please enter the Locker Number')">
                        <a-input style="width:100%" size="large" v-model="lockerModal.name" placeholder="Example value: L101" >
                        </a-input>
                    </a-form-model-item>
                </a-col>
				<a-col :span="5">
                    <a-form-model-item label="Locker Type" prop="type" :rules="req('Please select a Locker Type')">
                        <a-select
                            v-model="lockerModal.type"
                            size="large"
                            style="width: 100%"
                            option-label-prop="label"
                        >
                            <a-select-option value="Regular" label="Regular">Regular</a-select-option>
                            <a-select-option value="Large" label="Large">Large</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="5">
                    <a-form-model-item label="Locker Price" prop="price" :rules="req('Please enter the Locker Price')">
                        <a-input-number style="width:100%" size="large" v-model="lockerModal.price" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                        </a-input-number>
                    </a-form-model-item>
                </a-col>
                <a-col :span="5">
                    <a-form-model-item label="Locker Description" prop="description">
                        <a-input size="large" v-model="lockerModal.description" placeholder="Locker Description"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item label="Tag(s)" prop="tags">
                        <a-select
                            v-model="lockerModal.tags"
                            mode="tags"
                            size="large"
                            style="width: 100%"
                            placeholder="Tags"
                            option-label-prop="label"
                        >
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Unit #" prop="unitId">
                        <a-select
                            v-model="lockerModal.unitId"
							show-search
							option-filter-prop="unitNumber"
							:filter-option="filterOption"
                            mode="single"
                            size="large"
                            style="width: 100%"
                            placeholder="Unit #"
                            option-label-prop="label"
                            :disabled="edit && origUnitId != ''"
                        >
                            <template v-for="(unit,unitI) in units">
                                <a-select-option :key="unit+unitI" :value="unit.id" :label="`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`">{{`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="24">
                    <div class="w-full" style="text-align:right">
						<a-button size="large" class="cancel-button" @click="onClose">CANCEL</a-button>
						<a-button class="ml-3" size="large" type="primary" @click="submitLocker">{{edit ? 'SAVE' : 'ADD'}}</a-button>
                    </div>
                </a-col>
            </a-row>
        </a-form-model>
    </a-modal>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{bhLoading},
    prop:{

    },
    data() {
        return {
            loading:false,
            lockerModal:{
                id:'',
                lID:'',
                name:'',
                price:0,
                unitId:'',
                description:'',
                status:'available',
                tags:[]
            },
            origUnitId:''
        }
    },
    watch:{
        edit:{
            handler(val) {
                if (val) {
                    let type = this.$store.state.condoUnits.modal.type
                    let obj = JSON.parse(JSON.stringify(this.$store.state.condoUnits.modal.obj))
                    if (type == 'locker') {
                        this.lockerModal = obj
                        this.origUnitId = obj.unitId
                        if (this.lockerModal && !this.lockerModal.status){
                            this.lockerModal.status = 'available'
                        }
                    }
                }
            }
        }
    },
    computed:{
        edit() {
            return this.$store.state.condoUnits.modal.edit
        },
        modal(){
            return this.$store.state.condoUnits.modal
        },
        units(){
            return Object.values(this.$store.state.condoUnits.units).sort((a,b) => a.unitNumber - b.unitNumber);
        },
        settings() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options) return this.$store.state.condoUnits.allSettings.app.options
			return{};
        }
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        submitLocker() {
            if(this.edit) this.editLocker()
            else this.addLocker()
        },
        addLocker(){
            this.$refs.lockerModal.validate(valid => {
                if (valid) {
                    let sendObj = {options:{}, packages:[]}
                    let newLocker = JSON.parse(JSON.stringify(this.lockerModal))
                    newLocker.id = Date.now()
                    sendObj.options = JSON.parse(JSON.stringify(this.settings))
                    if (!sendObj.options.hasOwnProperty("addons") || (!sendObj.options.addons.parking && !sendObj.options.addons.lockers && !sendObj.options.addons.bikeRacks)){
                        sendObj.options.addons = {parking:[],lockers:[],bikeRacks:[]}
                    }
                    if (newLocker.unitId){
                        let unit = JSON.parse(JSON.stringify(this.units.find(x => x.id == newLocker.unitId)))
                        let packages = unit.packages
                        if (!packages[0].other.hasOwnProperty("addons") || (!packages[0].other.addons.parking && !packages[0].other.addons.lockers && !packages[0].other.addons.bikeRacks)){
                            packages[0].other.addons = {parking:[],lockers:[],bikeRacks:[]}
                        }
                        if (packages[0].maxLocker && packages[0].maxLocker > packages[0].other.addons.lockers.length){
                            sendObj.options.addons.lockers.push(newLocker)
                        } else {
                            return this.$message.error('The selected unit has reached the maximum number of Lockers.')
                        }
                        packages[0].other.addons.lockers.push(newLocker)
                        sendObj.packages = packages
						unit.packages = packages
                        this.loading = true
                        this.$api.put(`/units/:instance/${newLocker.unitId}/condounits`, sendObj).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.$store.commit('UPDATE_UNIT', unit)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    } else {
                        sendObj.options.addons.lockers.push(newLocker)
                        this.loading = true
                        this.$api.put(`/settings/:instance/condounits`, {options:sendObj.options}).then(({data}) => {
                            console.log('DATA COMING BACK', data)
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.log('ERROR', err)
                            this.loading = false
                        })
                    }
                }
            })
        },
        editLocker(){
            this.$refs.lockerModal.validate(valid => {
                if (valid) {
                    let sendObj = {options:{}, packages:[]}
                    let newLocker = JSON.parse(JSON.stringify(this.lockerModal))
                    sendObj.options = JSON.parse(JSON.stringify(this.settings))

					let foundLocker = sendObj.options.addons.lockers.findIndex(x => x.id == newLocker.id)
					if (foundLocker != -1) sendObj.options.addons.lockers[foundLocker] = newLocker

                    if (newLocker.unitId){
                        let unit = JSON.parse(JSON.stringify(this.units.find(x => x.id == newLocker.unitId)))
                        let packages = unit.packages

                        let foundPackageLocker = packages[0].other.addons.lockers.findIndex(x => x.id == newLocker.id)
                        if (foundPackageLocker != -1) packages[0].other.addons.lockers[foundPackageLocker] = newLocker
                        else {
                            packages[0].other.addons.lockers.push(newLocker)
                        }
                        sendObj.packages = packages
						unit.packages = packages
                        this.loading = true
                        this.$api.put(`/units/:instance/${newLocker.unitId}/condounits`, sendObj).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.$store.commit('UPDATE_UNIT', unit)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    } else {
                        this.loading = true
                        this.$api.put(`/settings/:instance/condounits`, {options:sendObj.options}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    }
                }
            })
        },
        onClose(){
            this.$store.commit('CLOSE_MODAL')
            this.lockerModal = {
                id:'',
                lID:'',
                name:'',
                price:0,
                unitId:'',
                description:'',
                status:'available',
                tags:[]
            }
        },

		filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
    }
}
</script>

<style>

.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
