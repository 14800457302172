<template>
    <a-modal :visible="modal.visible && modal.type == 'bikeRack'" :footer="null" :centered="true" @cancel="onClose" :width="'75%'">
        <bh-loading :show="loading" />
        <h5>Bike Rack</h5>
        <a-form-model style="margin-top:24px" :model="bikeModal" ref="bikeModal">
            <a-row :gutter="16">
                <a-col :span="5">
                    <a-form-model-item label="Bike Rack ID" prop="bID">
                        <a-input style="width:100%" size="large" v-model="bikeModal.bID" placeholder="Example value: 1" >
                        </a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="Bike Rack #" prop="name" :rules="req('Please enter the Bike Rack Number')">
                        <a-input style="width:100%" size="large" v-model="bikeModal.name" placeholder="Example value: B101" >
                        </a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="Bike Rack Price" prop="price" :rules="req('Please enter the Bike Rack Price')">
                        <a-input-number style="width:100%" size="large" v-model="bikeModal.price" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                        </a-input-number>
                    </a-form-model-item>
                </a-col>
                <a-col :span="7">
                    <a-form-model-item label="Bike Rack Description" prop="description">
                        <a-input size="large" v-model="bikeModal.description" placeholder="Bike Rack Description"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item label="Tag(s)" prop="tags">
                        <a-select
                            v-model="bikeModal.tags"
                            mode="tags"
                            size="large"
                            style="width: 100%"
                            placeholder="Tags"
                            option-label-prop="label"
                        >
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Unit #" prop="unitId">
                        <a-select
                            v-model="bikeModal.unitId"
							show-search
							option-filter-prop="unitNumber"
							:filter-option="filterOption"
                            mode="single"
                            size="large"
                            style="width: 100%"
                            placeholder="Unit #"
                            option-label-prop="label"
                            :disabled="edit && origUnitId != ''"
                        >
                            <template v-for="(unit,unitI) in units">
                                <a-select-option :key="unit+unitI" :value="unit.id" :label="`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`">{{`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="24">
                    <div class="w-full" style="text-align:right">
						<a-button size="large" class="cancel-button" @click="onClose">CANCEL</a-button>
						<a-button class="ml-3" size="large" type="primary" @click="submitBikeRack">{{edit ? 'SAVE' : 'ADD'}}</a-button>
                    </div>
                </a-col>
            </a-row>
        </a-form-model>
    </a-modal>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{bhLoading},
    prop:{

    },
    data() {
        return {
            loading:false,
            bikeModal:{
                id:'',
                bID:'',
                name:'',
                price:0,
                unitId:'',
                description:'',
                status:'available',
                tags:[]
            },
            origUnitId:'',
        }
    },
    watch:{
        edit:{
            handler(val) {
                if (val) {
                    let type = this.$store.state.condoUnits.modal.type
                    let obj = JSON.parse(JSON.stringify(this.$store.state.condoUnits.modal.obj))
                    if (type == 'bikeRack') {
                        this.bikeModal = obj
                        this.origUnitId = obj.unitId
                        if (!this.bikeModal.status){
                            this.bikeModal.status = 'available'
                        }
                    }
                }
            }
        }
    },
    computed:{
        edit() {
            return this.$store.state.condoUnits.modal.edit
        },
        modal(){
            return this.$store.state.condoUnits.modal
        },
        units(){
			return Object.values(this.$store.state.condoUnits.units).sort((a,b) => a.unitNumber - b.unitNumber);
        },
        settings() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options) return this.$store.state.condoUnits.allSettings.app.options
			return {};
		}
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        submitBikeRack() {
            if(this.edit) this.editBikeRack()
            else this.addBikeRack()
        },
        addBikeRack(){
            this.$refs.bikeModal.validate(valid => {
                if (valid) {
                    let sendObj = {options:{}, packages:[]}
                    let newBikeRack = JSON.parse(JSON.stringify(this.bikeModal))
                    newBikeRack.id = Date.now()
                    sendObj.options = JSON.parse(JSON.stringify(this.settings))
                    if (!sendObj.options.hasOwnProperty("addons") || (!sendObj.options.addons.parking && !sendObj.options.addons.lockers && !sendObj.options.addons.bikeRacks)){
                        sendObj.options.addons = {parking:[],lockers:[],bikeRacks:[]}
                    }
                    if (newBikeRack.unitId){
						let unit = this.units.find(x => x.id == newBikeRack.unitId)
						if(!unit){
							return this.$message.error('The no unit found. Please refresh the page and try again.')
						}
                        unit = JSON.parse(JSON.stringify(unit))
                        let packages = unit.packages
                        if (!packages[0].other.hasOwnProperty("addons") || (!packages[0].other.addons.parking && !packages[0].other.addons.lockers && !packages[0].other.addons.bikeRacks)){
                            packages[0].other.addons = {parking:[],lockers:[],bikeRacks:[]}
                        }
                        if (packages[0].maxBikeRack && packages[0].maxBikeRack > packages[0].other.addons.bikeRacks.length){
                            sendObj.options.addons.bikeRacks.push(newBikeRack)
                        } else {
                            return this.$message.error('The selected unit has reached the maximum number of Bike Racks.')
                        }
                        packages[0].other.addons.bikeRacks.push(newBikeRack)
                        sendObj.packages = packages
						unit.packages = packages
                        this.loading = true
                        this.$api.put(`/units/:instance/${newBikeRack.unitId}/condounits`, sendObj).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.$store.commit('UPDATE_UNIT', unit)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    } else {
                        sendObj.options.addons.bikeRacks.push(newBikeRack)
                        this.loading = true
                        this.$api.put(`/settings/:instance/condounits`, {options:sendObj.options}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    }
                }
            })
        },
        editBikeRack(){
            this.$refs.bikeModal.validate(valid => {
                if (valid) {
                    let sendObj = {options:{}, packages:[]}
                    let newBikeRack = JSON.parse(JSON.stringify(this.bikeModal))
                    sendObj.options = JSON.parse(JSON.stringify(this.settings))

					let foundBikeRack = sendObj.options.addons.bikeRacks.findIndex(x => x.id == newBikeRack.id)
                    if (foundBikeRack != -1) sendObj.options.addons.bikeRacks[foundBikeRack] = newBikeRack

                    if (newBikeRack.unitId){
                        let unit = JSON.parse(JSON.stringify(this.units.find(x => x.id == newBikeRack.unitId)))
                        let packages = unit.packages


                        let foundPackageBikeRack = packages[0].other.addons.bikeRacks.findIndex(x => x.id == newBikeRack.id)
                        if (foundPackageBikeRack != -1) packages[0].other.addons.bikeRacks[foundPackageBikeRack] = newBikeRack
                        else {
                            packages[0].other.addons.bikeRacks.push(newBikeRack)
                        }
                        sendObj.packages = packages
						unit.packages = packages
                        this.loading = true
                        this.$api.put(`/units/:instance/${newBikeRack.unitId}/condounits`, sendObj).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.$store.commit('UPDATE_UNIT', unit)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    } else {
                        this.loading = true
                        this.$api.put(`/settings/:instance/condounits`, {options:sendObj.options}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    }
                }
            })
        },
        onClose(){
            this.$store.commit('CLOSE_MODAL')
            this.bikeModal = {
                id:'',
                bID:'',
                name:'',
                price:0,
                unitId:'',
                description:'',
                status:'available',
                tags:[]
            }
        },

		filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
    }
}
</script>

<style>

.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
