<template>
    <a-modal :visible="modal.visible && modal.type == 'parking'" :footer="null" :centered="true" @cancel="onClose" :width="'75%'">
        <bh-loading :show="loading" />
        <h5>Parking Spot</h5>
        <a-form-model style="margin-top:24px" :model="parkingModal" ref="parkingModal">
            <a-row :gutter="16">
                <a-col :span="4">
                    <a-form-model-item label="Parking ID" prop="pID">
                        <a-input style="width:100%" size="large" v-model="parkingModal.pID" placeholder="Example value: 1" >
                        </a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="5">
                    <a-form-model-item label="Parking Spot #" prop="name" :rules="req('Please enter the Parking Spot Number')">
                        <a-input style="width:100%" size="large" v-model="parkingModal.name" placeholder="Example value: P101" >
                        </a-input>
                    </a-form-model-item>
                </a-col>
				<a-col :span="4">
                    <a-form-model-item label="Parking Type" prop="type" :rules="req('Please select a Parking Type')">
                        <a-select
                            v-model="parkingModal.type"
                            size="large"
                            style="width: 100%"
                            option-label-prop="label"
                        >
                            <a-select-option value="Regular" label="Regular">Regular</a-select-option>
                            <a-select-option value="EV" label="EV">EV</a-select-option>
                            <a-select-option value="Tandem" label="Tandem">Tandem</a-select-option>
                            <a-select-option value="Extra Large" label="Extra Large">Extra Large</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="5">
                    <a-form-model-item label="Parking Spot Price" prop="price" :rules="req('Please enter the Parking Spot Price')">
                        <a-input-number style="width:100%" size="large" v-model="parkingModal.price" :formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Example value: 1" >
                        </a-input-number>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="Parking Spot Description" prop="description">
                        <a-input size="large" v-model="parkingModal.description" placeholder="Parking Spot Description"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item label="Tag(s)" prop="tags">
                        <a-select
                            v-model="parkingModal.tags"
                            mode="tags"
                            size="large"
                            style="width: 100%"
                            placeholder="Tags"
                            option-label-prop="label"
                        >
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Unit #" prop="unitId">
                        <a-select
                            v-model="parkingModal.unitId"
							show-search
							option-filter-prop="unitNumber"
							:filter-option="filterOption"
                            mode="single"
                            size="large"
                            style="width: 100%"
                            placeholder="Unit #"
                            option-label-prop="label"
                            :disabled="edit && origUnitId != ''"
                        >
                            <template v-for="(unit,unitI) in units">
                                <a-select-option :key="unit+unitI" :value="unit.id" :label="`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`">{{`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="24">
                    <div class="w-full" style="text-align:right">
						<a-button size="large" class="cancel-button" @click="onClose">CANCEL</a-button>
						<a-button class="ml-3" size="large" type="primary" @click="submitParking">{{edit ? 'SAVE' : 'ADD'}}</a-button>
                    </div>
                </a-col>
            </a-row>
        </a-form-model>
    </a-modal>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{bhLoading},
    prop:{

    },
    data() {
        return {
            loading:false,
            parkingModal:{
                id:'',
                pID:'',
                name:'',
                price:0,
                unitId:'',
                description:'',
                type:'',
                status:'available',
                tags:[]
            },
            origUnitId:''
        }
    },
    watch:{
        edit:{
            handler(val) {
                if (val) {
                    let type = this.$store.state.condoUnits.modal.type
                    let obj = JSON.parse(JSON.stringify(this.$store.state.condoUnits.modal.obj))
                    if (type == 'parking') {
                        this.parkingModal = obj
                        this.origUnitId = obj.unitId
                        if (!this.parkingModal.status){
                            this.parkingModal.status = 'available'
                        }
                    }
                }
            }
        }
    },
    computed:{
        edit() {
            return this.$store.state.condoUnits.modal.edit
        },
        modal(){
            return this.$store.state.condoUnits.modal
        },
        units(){
            return Object.values(this.$store.state.condoUnits.units).sort((a,b) => a.unitNumber - b.unitNumber);
        },
        settings() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options) return this.$store.state.condoUnits.allSettings.app.options
			return{};
        }
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        submitParking() {
            if(this.edit) this.editParking()
            else this.addParking()
        },
        addParking(){
            this.$refs.parkingModal.validate(valid => {
                if (valid) {
                    let sendObj = {options:{}, packages:[]}
                    let newParking = JSON.parse(JSON.stringify(this.parkingModal))
                    newParking.id = Date.now()
                    sendObj.options = JSON.parse(JSON.stringify(this.settings))
                    if (!sendObj.options.hasOwnProperty("addons") || (!sendObj.options.addons.parking && !sendObj.options.addons.lockers && !sendObj.options.addons.bikeRacks)) {
                        sendObj.options.addons = {parking:[],lockers:[],bikeRacks:[]}
                    }
                    if (newParking.unitId){
                        let unit = JSON.parse(JSON.stringify(this.units.find(x => x.id == newParking.unitId)))
                        let packages = unit.packages
                        if (!packages[0].other.hasOwnProperty("addons") || (!packages[0].other.addons.parking && !packages[0].other.addons.lockers && !packages[0].other.addons.bikeRacks)) {
                            packages[0].other.addons = {parking:[],lockers:[],bikeRacks:[]}
                        }
                        if (packages[0].maxParking && packages[0].maxParking > packages[0].other.addons.parking.length){
                            sendObj.options.addons.parking.push(newParking)
                        } else {
                            return this.$message.error('The selected unit has reached the maximum number of parking spots.')
                        }
                        packages[0].other.addons.parking.push(newParking)
                        sendObj.packages = packages
						unit.packages = packages
                        this.loading = true
                        this.$api.put(`/units/:instance/${newParking.unitId}/condounits`, sendObj).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.$store.commit('UPDATE_UNIT', unit)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    } else {
                        sendObj.options.addons.parking.push(newParking)
                        this.loading = true
                        this.$api.put(`/settings/:instance/condounits`, {options:sendObj.options}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    }

                }
            })
        },
        editParking(){
            this.$refs.parkingModal.validate(valid => {
                if (valid) {
                    let sendObj = {options:{}, packages:[]}
                    let newParking = JSON.parse(JSON.stringify(this.parkingModal))
                    sendObj.options = JSON.parse(JSON.stringify(this.settings))

					let foundParking = sendObj.options.addons.parking.findIndex(x => x.id == newParking.id)
                    if (foundParking != -1) sendObj.options.addons.parking[foundParking] = newParking

                    if (newParking.unitId){
                        let unit = JSON.parse(JSON.stringify(this.units.find(x => x.id == newParking.unitId)))
                        let packages = unit.packages


                        let foundPackageParking = packages[0].other.addons.parking.findIndex(x => x.id == newParking.id)
                        if (foundPackageParking != -1) packages[0].other.addons.parking[foundPackageParking] = newParking
                        else {
                            packages[0].other.addons.parking.push(newParking)
                        }
                        sendObj.packages = packages
						unit.packages = packages
                        this.loading = true
                        this.$api.put(`/units/:instance/${newParking.unitId}/condounits`, sendObj).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.$store.commit('UPDATE_UNIT', unit)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    } else {
                        this.loading = true
                        this.$api.put(`/settings/:instance/condounits`, {options:sendObj.options}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', {options:sendObj.options})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
                            console.error('ERROR', err)
                            this.loading = false
                        })
                    }
                }
            })
        },
        onClose(){
            this.$store.commit('CLOSE_MODAL')
            this.parkingModal = {
                id:'',
                pID:'',
                name:'',
                price:0,
                unitId:'',
                description:'',
                type:'',
                status:'available',
                tags:[]
            }
        },

		filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
    }
}
</script>

<style>

.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
