<template>
    <div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
        <ParkingModal />
        <LockerModal />
        <BikeRackModal />
        <bh-loading :show="loading" />
        <a-card>
            <a-tabs class="addOnTab" default-active-key="1">
                <a-tab-pane key="1" tab="Parking Spot">
                    <a-table
                        :rowKey="(e) => e.id"
                        :columns="parkingColumns"
                        class="white-table"
                        :data-source="parking"
                        @change="parkingChange"
                    >
                        <div slot="unitId" slot-scope="obj" class="dF">
                            {{ unitName(obj) }}
                        </div>
						<div slot="tags" slot-scope="obj" class="dF">
                            <a-tag v-for="(tag, index) in obj.tags" :key="index">{{tag}}</a-tag>
                        </div>
                        <div slot="action" slot-scope="obj" class="dF">
                            <div
                                @click="editAddon(obj, 'parking')"
                                style="cursor: pointer"
                                class="mr-3"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit-2"
                                >
                                    <path
                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                @click="copyAddon(obj, 'parking')"
                                style="cursor: pointer"
                                class="mr-3"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-copy"
                                >
                                    <rect
                                        x="9"
                                        y="9"
                                        width="13"
                                        height="13"
                                        rx="2"
                                        ry="2"
                                    ></rect>
                                    <path
                                        d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                @click="deleteAddon(obj, 'parking')"
                                style="color: #fd647c; cursor: pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2"
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                    ></line>
                                    <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                    ></line>
                                </svg>
                            </div>
                        </div>
                    </a-table>
                    <div
                        @click="addAddon('parking')"
						class="addButton"
                    >
                        <div class="dF aC">
                            <a-icon
                                style="font-size: 20px"
                                class="mr-3"
                                type="plus-circle"
                            />
                            <div>Add New Add On</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Locker">
                    <a-table
                        :rowKey="(e) => e.id"
                        :columns="lockerColumns"
                        class="white-table"
                        :data-source="lockers"
                        @change="lockerChange"
                    >
                        <div slot="unitId" slot-scope="obj" class="dF">
                            {{ unitName(obj) }}
                        </div>
						<div slot="tags" slot-scope="obj" class="dF">
                            <a-tag v-for="(tag, index) in obj.tags" :key="index">{{tag}}</a-tag>
                        </div>
                        <div slot="action" slot-scope="obj" class="dF">
                            <div
                                @click="editAddon(obj, 'locker')"
                                style="cursor: pointer"
                                class="mr-3"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit-2"
                                >
                                    <path
                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                @click="copyAddon(obj, 'locker')"
                                style="cursor: pointer"
                                class="mr-3"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-copy"
                                >
                                    <rect
                                        x="9"
                                        y="9"
                                        width="13"
                                        height="13"
                                        rx="2"
                                        ry="2"
                                    ></rect>
                                    <path
                                        d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                @click="deleteAddon(obj, 'locker')"
                                style="color: #fd647c; cursor: pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2"
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                    ></line>
                                    <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                    ></line>
                                </svg>
                            </div>
                        </div>
                    </a-table>
                    <div
                        @click="addAddon('locker')"
                        class="addButton"
                    >
                        <div class="dF aC">
                            <a-icon
                                style="font-size: 20px"
                                class="mr-3"
                                type="plus-circle"
                            />
                            <div>Add New Add On</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="Bike Rack">
                    <a-table
                        :rowKey="(e) => e.id"
                        :columns="bikeColumns"
                        class="white-table"
                        :data-source="bikeRacks"
                        @change="bikeRackChange"
                    >
                        <div slot="unitId" slot-scope="obj" class="dF">
                            {{ unitName(obj) }}
                        </div>
						<div slot="tags" slot-scope="obj" class="dF">
                            <a-tag v-for="(tag, index) in obj.tags" :key="index">{{tag}}</a-tag>
                        </div>
                        <div slot="action" slot-scope="obj" class="dF">
                            <div
                                @click="editAddon(obj, 'bikeRack')"
                                style="cursor: pointer"
                                class="mr-3"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit-2"
                                >
                                    <path
                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                @click="copyAddon(obj, 'bikeRack')"
                                style="cursor: pointer"
                                class="mr-3"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-copy"
                                >
                                    <rect
                                        x="9"
                                        y="9"
                                        width="13"
                                        height="13"
                                        rx="2"
                                        ry="2"
                                    ></rect>
                                    <path
                                        d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                                    ></path>
                                </svg>
                            </div>
                            <div
                                @click="deleteAddon(obj, 'bikeRack')"
                                style="color: #fd647c; cursor: pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2"
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                    ></line>
                                    <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                    ></line>
                                </svg>
                            </div>
                        </div>
                    </a-table>
                    <div
                        @click="addAddon('bikeRack')"
                        class="addButton"
                    >
                        <div class="dF aC">
                            <a-icon
                                style="font-size: 20px"
                                class="mr-3"
                                type="plus-circle"
                            />
                            <div>Add New Add On</div>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-card>
    </div>
</template>

<script>
import ParkingModal from "@/components/condounits/ParkingModal";
import LockerModal from "@/components/condounits/LockerModal";
import BikeRackModal from "@/components/condounits/BikeRackModal";
import bhLoading from "bh-mod/components/common/Loading";
export default {
    components: { ParkingModal, LockerModal, BikeRackModal, bhLoading },
    computed: {
		dateFormat(){
			return this.$store.state.condoUnits.allSettings.user && this.$store.state.condoUnits.allSettings.user.options && this.$store.state.condoUnits.allSettings.user.options.regional && this.$store.state.condoUnits.allSettings.user.options.regional.dateFormat ? this.$store.state.condoUnits.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        addOns() {
            if (
                this.$store.state.condoUnits.allSettings &&
                this.$store.state.condoUnits.allSettings.app &&
                this.$store.state.condoUnits.allSettings.app.options &&
                this.$store.state.condoUnits.allSettings.app.options.addons &&
                Object.values(
                    this.$store.state.condoUnits.allSettings.app.options.addons
                ).length
            ) {
                return this.$store.state.condoUnits.allSettings.app.options
                    .addons;
            }
            return {
                parking: [],
                lockers: [],
                bikeRacks: [],
            };
        },
        parking() {
            if (!this.parkingSorter.hasOwnProperty("order")) {
                return this.addOns.parking;
            } else {
                let parking = JSON.parse(JSON.stringify(this.addOns.parking));
                if (this.parkingSorter.columnKey != "unitId") {
                    parking = parking.sort((a, b) => {
                        if (this.parkingSorter.order === "ascend") {
                            //sort pID field by number and alphabetical by ascending order
                            if (
                                a[this.parkingSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/) &&
                                b[this.parkingSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/)
                            ) {
                                return (
                                    a[this.parkingSorter.columnKey] -
                                    b[this.parkingSorter.columnKey]
                                );
                            } else {
                                return a[
                                    this.parkingSorter.columnKey
                                ].localeCompare(
                                    b[this.parkingSorter.columnKey]
                                );
                            }
                        } else if (this.parkingSorter.order === "descend") {
                            if (
                                b[this.parkingSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/) &&
                                a[this.parkingSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/)
                            ) {
                                return (
                                    b[this.parkingSorter.columnKey] -
                                    a[this.parkingSorter.columnKey]
                                );
                            } else {
                                return b[
                                    this.parkingSorter.columnKey
                                ].localeCompare(
                                    a[this.parkingSorter.columnKey]
                                );
                            }
                        }
                    });
                } else {
                    parking = parking.sort((a, b) => {
                        if (this.parkingSorter.order === "ascend") {
                            return (
                                this.storeUnits[a[this.parkingSorter.columnKey]]
                                    .unitNumber -
                                this.storeUnits[b[this.parkingSorter.columnKey]]
                                    .unitNumber
                            );
                        } else if (this.parkingSorter.order === "descend") {
                            return (
                                this.storeUnits[b[this.parkingSorter.columnKey]]
                                    .unitNumber -
                                this.storeUnits[a[this.parkingSorter.columnKey]]
                                    .unitNumber
                            );
                        }
                    });
                }
                return parking;
            }
        },
        lockers() {
            if (!this.lockerSorter.hasOwnProperty("order")) {
                return this.addOns.lockers;
            } else {
                let lockers = JSON.parse(JSON.stringify(this.addOns.lockers));
                if (this.lockerSorter.columnKey != "unitId") {
                    lockers = lockers.sort((a, b) => {
                        if (this.lockerSorter.order === "ascend") {
                            //sort pID field by number and alphabetical by ascending order
                            if (
                                a[this.lockerSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/) &&
                                b[this.lockerSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/)
                            ) {
                                return (
                                    a[this.lockerSorter.columnKey] -
                                    b[this.lockerSorter.columnKey]
                                );
                            } else {
                                return a[
                                    this.lockerSorter.columnKey
                                ].localeCompare(b[this.lockerSorter.columnKey]);
                            }
                        } else if (this.lockerSorter.order === "descend") {
                            if (
                                b[this.lockerSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/) &&
                                a[this.lockerSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/)
                            ) {
                                return (
                                    b[this.lockerSorter.columnKey] -
                                    a[this.lockerSorter.columnKey]
                                );
                            } else {
                                return b[
                                    this.lockerSorter.columnKey
                                ].localeCompare(a[this.lockerSorter.columnKey]);
                            }
                        }
                    });
                } else {
                    lockers = lockers.sort((a, b) => {
                        if (this.lockerSorter.order === "ascend") {
                            return (
                                this.storeUnits[a[this.lockerSorter.columnKey]]
                                    .unitNumber -
                                this.storeUnits[b[this.lockerSorter.columnKey]]
                                    .unitNumber
                            );
                        } else if (this.parkingSorter.order === "descend") {
                            return (
                                this.storeUnits[b[this.lockerSorter.columnKey]]
                                    .unitNumber -
                                this.storeUnits[a[this.lockerSorter.columnKey]]
                                    .unitNumber
                            );
                        }
                    });
                }
                return lockers;
            }
        },
        bikeRacks() {
            if (!this.bikeRackSorter.hasOwnProperty("order")) {
                return this.addOns.bikeRacks;
            } else {
                let bikeRacks = JSON.parse(
                    JSON.stringify(this.addOns.bikeRacks)
                );
                if (this.bikeRackSorter.columnKey != "unitId") {
                    bikeRacks = bikeRacks.sort((a, b) => {
                        if (this.bikeRackSorter.order === "ascend") {
                            //sort pID field by number and alphabetical by ascending order
                            if (
                                a[this.bikeRackSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/) &&
                                b[this.bikeRackSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/)
                            ) {
                                return (
                                    a[this.bikeRackSorter.columnKey] -
                                    b[this.bikeRackSorter.columnKey]
                                );
                            } else {
                                return a[
                                    this.bikeRackSorter.columnKey
                                ].localeCompare(
                                    b[this.bikeRackSorter.columnKey]
                                );
                            }
                        } else if (this.bikeRackSorter.order === "descend") {
                            if (
                                b[this.bikeRackSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/) &&
                                a[this.bikeRackSorter.columnKey]
                                    .toString()
                                    .match(/^\d+$/)
                            ) {
                                return (
                                    b[this.bikeRackSorter.columnKey] -
                                    a[this.bikeRackSorter.columnKey]
                                );
                            } else {
                                return b[
                                    this.bikeRackSorter.columnKey
                                ].localeCompare(
                                    a[this.bikeRackSorter.columnKey]
                                );
                            }
                        }
                    });
                } else {
                    bikeRacks = bikeRacks.sort((a, b) => {
                        if (this.bikeRackSorter.order === "ascend") {
                            return (
                                this.storeUnits[
                                    a[this.bikeRackSorter.columnKey]
                                ].unitNumber -
                                this.storeUnits[
                                    b[this.bikeRackSorter.columnKey]
                                ].unitNumber
                            );
                        } else if (this.parkingSorter.order === "descend") {
                            return (
                                this.storeUnits[
                                    b[this.bikeRackSorter.columnKey]
                                ].unitNumber -
                                this.storeUnits[
                                    a[this.bikeRackSorter.columnKey]
                                ].unitNumber
                            );
                        }
                    });
                }
                return bikeRacks;
            }
        },
        storeUnits() {
            return this.$store.state.condoUnits.units;
        },
        units() {
            return Object.values(this.$store.state.condoUnits.units);
        },
        settings() {
            if (
                this.$store.state.condoUnits.allSettings &&
                this.$store.state.condoUnits.allSettings.app &&
                this.$store.state.condoUnits.allSettings.app.options
            )
                return this.$store.state.condoUnits.allSettings.app.options;
            return {};
        },
    },
    data() {
        return {
            loading: false,
            parkingSorter: {},
            lockerSorter: {},
            bikeRackSorter: {},
            parkingColumns: [
                {
                    dataIndex: "pID",
                    sorter: true,
                    key: "pID",
                    title: "Parking ID",
                },
                {
                    dataIndex: "name",
                    sorter: true,
                    key: "name",
                    title: "Parking Spot #",
                },
                {
                    dataIndex: "type",
                    sorter: true,
                    key: "type",
                    title: "Parking Spot Type",
                },
                {
                    dataIndex: "price",
                    sorter: true,
                    key: "price",
                    title: "Parking Spot Price",
                },
                {
                    dataIndex: "description",
                    key: "description",
                    title: "Parking Spot Description",
                },
                {
                    title: "Unit #",
                    sorter: true,
                    key: "unitId",
                    slots: { title: "Unit #" },
                    scopedSlots: { customRender: "unitId" },
                },
                {
                    title: "Tag(s)",
                    key: "tags",
                    slots: { title: "Tag(s)" },
                    scopedSlots: { customRender: "tags" },
                },
                {
                    title: "Actions",
                    key: "action",
                    slots: { title: "Actions" },
                    scopedSlots: { customRender: "action" },
                },
            ],
            lockerColumns: [
                {
                    dataIndex: "lID",
                    sorter: true,
                    key: "lID",
                    title: "Locker ID",
                },
                {
                    dataIndex: "name",
                    sorter: true,
                    key: "name",
                    title: "Locker #",
                },
                {
                    dataIndex: "type",
                    sorter: true,
                    key: "type",
                    title: "Locker Type",
                },
                {
                    dataIndex: "price",
                    sorter: true,
                    key: "price",
                    title: "Locker Price",
                },
                {
                    dataIndex: "description",
                    key: "description",
                    title: "Locker Description",
                },
                {
                    title: "Unit #",
                    sorter: true,
                    key: "unitId",
                    slots: { title: "Unit #" },
                    scopedSlots: { customRender: "unitId" },
                },
                {
                    title: "Tag(s)",
                    key: "tags",
                    slots: { title: "Tag(s)" },
                    scopedSlots: { customRender: "tags" },
                },
                {
                    title: "Actions",
                    key: "action",
                    slots: { title: "Actions" },
                    scopedSlots: { customRender: "action" },
                },
            ],
            bikeColumns: [
                {
                    dataIndex: "bID",
                    sorter: true,
                    key: "bID",
                    title: "Bike Rack ID",
                },
                {
                    dataIndex: "name",
                    sorter: true,
                    key: "name",
                    title: "Bike Rack #",
                },
                {
                    dataIndex: "price",
                    sorter: true,
                    key: "price",
                    title: "Bike Rack Price",
                },
                {
                    dataIndex: "description",
                    key: "description",
                    title: "Bike Rack Description",
                },
                {
                    title: "Unit #",
                    sorter: true,
                    key: "unitId",
                    slots: { title: "Unit #" },
                    scopedSlots: { customRender: "unitId" },
                },
                {
                    title: "Tag(s)",
                    key: "tags",
                    slots: { title: "Tag(s)" },
                    scopedSlots: { customRender: "tags" },
                },
                {
                    title: "Actions",
                    key: "action",
                    slots: { title: "Actions" },
                    scopedSlots: { customRender: "action" },
                },
            ],
        };
    },
    methods: {
        parkingChange(pagination, filters, sorter) {
            this.parkingSorter = sorter;
        },
        lockerChange(pagination, filters, sorter) {
            this.lockerSorter = sorter;
        },
        bikeRackChange(pagination, filters, sorter) {
            this.bikeRackSorter = sorter;
        },
        unitName(obj) {
            let foundUnit = this.units.find((x) => x.id == obj.unitId);
            if (foundUnit) {
                return foundUnit.unitNumber;
            } else {
                return "";
            }
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        req: (msg) => ({ required: true, message: msg }),

        addAddon(type) {
            this.$store.commit("OPEN_MODAL", type);
        },
        editAddon(obj, type) {
            this.$store.commit("EDIT_MODAL", { type: type, obj: obj });
        },
        copyAddon(obj, type) {
            let sendObj = { options: {}, packages: [] };
            sendObj.options = JSON.parse(JSON.stringify(this.settings));
            let newAddon = JSON.parse(JSON.stringify(obj));
            let unit = {};
            let packages = [];
            if (newAddon.unitId) {
				unit = this.units.find((x) => x.id == newAddon.unitId)
				if(!unit){
					return this.$message.error('The no unit found. Please refresh the page and try again.')
				}
                unit = JSON.parse(JSON.stringify(unit));
                packages = unit.packages;
            }
            if (type === "parking") {
                newAddon.id = Date.now();
                newAddon.pID = newAddon.pID + " (copy)";
                if (newAddon.unitId) {
                    if (
                        packages[0].maxParking &&
                        packages[0].maxParking >
                            packages[0].other.addons.parking.length
                    ) {
                        sendObj.options.addons.parking.push(newAddon);
                    } else {
                        return this.$message.error(
                            "The selected unit has reached the maximum number of parking spots."
                        );
                    }
                    packages[0].other.addons.parking.push(newAddon);
                } else {
                    sendObj.options.addons.parking.push(newAddon);
                }
            } else if (type === "locker") {
                newAddon.id = Date.now();
                newAddon.lID = newAddon.lID + " (copy)";
                if (newAddon.unitId) {
                    if (
                        packages[0].maxLocker &&
                        packages[0].maxLocker >
                            packages[0].other.addons.lockers.length
                    ) {
                        sendObj.options.addons.lockers.push(newAddon);
                    } else {
                        return this.$message.error(
                            "The selected unit has reached the maximum number of lockers."
                        );
                    }
                    packages[0].other.addons.lockers.push(newAddon);
                } else {
                    sendObj.options.addons.lockers.push(newAddon);
                }
            } else if (type === "bikeRack") {
                newAddon.id = Date.now();
                newAddon.bID = newAddon.bID + " (copy)";
                if (newAddon.unitId) {
                    if (
                        packages[0].maxBikeRack &&
                        packages[0].maxBikeRack >
                            packages[0].other.addons.bikeRacks.length
                    ) {
                        sendObj.options.addons.bikeRacks.push(newAddon);
                    } else {
                        return this.$message.error(
                            "The selected unit has reached the maximum number of Bike Racks."
                        );
                    }
                    packages[0].other.addons.bikeRacks.push(newAddon);
                } else {
                    sendObj.options.addons.bikeRacks.push(newAddon);
                }
            }
            if (newAddon.unitId) {
                sendObj.packages = packages;
                this.loading = true;
                this.$api
                    .put(
                        `/units/:instance/${newAddon.unitId}/condounits`,
                        sendObj
                    )
                    .then(({ data }) => {
                        this.$store.commit("UPDATE_SETTINGS", {
                            options: sendObj.options,
                        });
                        this.$store.commit("UPDATE_UNIT", unit);
                        this.$message.success(
                            "Parking spot copied successfully."
                        );
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error("ERROR", err);
                        this.loading = false;
                    });
            } else {
                this.loading = true;
                this.$api
                    .put(`/settings/:instance/condounits`, {
                        options: sendObj.options,
                    })
                    .then(() => {
                        this.$store.commit("UPDATE_SETTINGS", {
                            options: sendObj.options,
                        });
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error("ERROR", err);
                        this.loading = false;
                    });
            }
        },
        deleteAddon(obj, type) {
            let self = this;
            if (this.$p < 20)
                return this.$message.error(
                    "You do not have permission to delete unit add ons"
                );
            this.$confirm({
                title: "Delete Parking Spot",
                content: (h) => <div>Do you want to delete this Add On?</div>,
                okText: "DELETE",
                okType: "danger",
                cancelText: "CANCEL",
                centered: true,
                onOk() {
                    let sendObj = { options: {}, packages: [] };
                    sendObj.options = JSON.parse(JSON.stringify(self.settings));
                    let newAddon = JSON.parse(JSON.stringify(obj));
                    let unit = {};
                    let packages = [];
                    if (newAddon.unitId) {
						unit = self.units.find((x) => x.id == newAddon.unitId)
						if(!unit){
							return self.$message.error('The no unit found. Please refresh the page and try again.')
						}
                        unit = JSON.parse(JSON.stringify(unit));
                        packages = unit.packages;
                    }

                    if (type == "parking") {
                        let foundSetting =
                            sendObj.options.addons.parking.findIndex(
                                (x) => x.id == newAddon.id
                            );
                        if (foundSetting != -1)
                            sendObj.options.addons.parking.splice(
                                foundSetting,
                                1
                            );
                        if (newAddon.unitId) {
                            let foundPackage =
                                packages[0].other.addons.parking.findIndex(
                                    (x) => x.id == newAddon.id
                                );
                            if (foundPackage != -1)
                                packages[0].other.addons.parking.splice(
                                    foundPackage,
                                    1
                                );
                        }
                    } else if (type == "locker") {
                        let foundSetting =
                            sendObj.options.addons.lockers.findIndex(
                                (x) => x.id == newAddon.id
                            );
                        if (foundSetting != -1)
                            sendObj.options.addons.lockers.splice(
                                foundSetting,
                                1
                            );
                        if (newAddon.unitId) {
                            let foundPackage =
                                packages[0].other.addons.lockers.findIndex(
                                    (x) => x.id == newAddon.id
                                );
                            if (foundPackage != -1)
                                packages[0].other.addons.lockers.splice(
                                    foundPackage,
                                    1
                                );
                        }
                    } else if (type == "bikeRack") {
                        let foundSetting =
                            sendObj.options.addons.bikeRacks.findIndex(
                                (x) => x.id == newAddon.id
                            );
                        if (foundSetting != -1)
                            sendObj.options.addons.bikeRacks.splice(
                                foundSetting,
                                1
                            );
                        if (newAddon.unitId) {
                            let foundPackage =
                                packages[0].other.addons.bikeRacks.findIndex(
                                    (x) => x.id == newAddon.id
                                );
                            if (foundPackage != -1)
                                packages[0].other.addons.bikeRacks.splice(
                                    foundPackage,
                                    1
                                );
                        }
                    }
                    if (newAddon.unitId) {
                        sendObj.packages = packages;
                        self.loading = true;
                        self.$api
                            .put(
                                `/units/:instance/${newAddon.unitId}/condounits`,
                                sendObj
                            )
                            .then(({ data }) => {
                                self.$store.commit("UPDATE_SETTINGS", {
                                    options: sendObj.options,
                                });
                                self.$store.commit("UPDATE_UNIT", unit);
                                self.$message.success(
                                    "Add On deleted successfully."
                                );
                                self.loading = false;
                            })
                            .catch((err) => {
                                console.error("ERROR", err);
                                self.loading = false;
                            });
                    } else {
                        self.loading = true;
                        self.$api
                            .put(`/settings/:instance/condounits`, {
                                options: sendObj.options,
                            })
                            .then(({ data }) => {
                                self.$store.commit("UPDATE_SETTINGS", {
                                    options: sendObj.options,
                                });
                                self.loading = false;
                            })
                            .catch((err) => {
                                console.error("ERROR", err);
                                self.loading = false;
                            });
                    }
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        },
    },
};
</script>

<style>
.cancel-button.ant-btn {
    border-color: #ece9f1 !important;
    background-color: #ece9f1;
    color: #3f3356;
}
</style>

<style lang="scss">
.addOnTab .ant-tabs-nav {
	font-size: 16px;

	.ant-tabs-tab {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.addOnTab .ant-tabs-bar {
	margin: 0 0 25px 0;
}

.addOnTab .ant-tabs-top-bar {
	border-bottom: none !important;
}
</style>

<style scoped>
.addButton {
	cursor: pointer;
    color: var(--orange);
    display: inline-block;
}
</style>
